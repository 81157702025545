<template>
  <div class="home">
    <banners
      v-if="currentLocaleContent?.banners || defaultLocaleContent?.banners"
      :items="currentLocaleContent?.banners || defaultLocaleContent?.banners"
      :bannerLoyalty="currentLocaleContent?.bannerLoyalty || defaultLocaleContent?.bannerLoyalty"
    />
    
    <div
      v-if="currentLocaleContent?.categories || defaultLocaleContent?.categories"
      class="card-category__container"
      :class="cardsModifier"
    >
      <card-category
        v-for="(item, itemIndex) in (currentLocaleContent?.categories || defaultLocaleContent?.categories)"
        :key="itemIndex"
        :mod="itemIndex + 1"
        v-bind="item"
      />
    </div>

    <!--<group-benefits/>-->
    
    <group-aero
      v-if="currentLocaleContent?.aeroGroup?.display && aeroCategory"
      showAllBtn
      showArrows
      :category="aeroCategory"
      :currentLocaleContent="currentLocaleContent?.aeroGroup"
      :defaultLocaleContent="defaultLocaleContent?.aeroGroup"
    />
    
    <template v-for="collection in gameCollectionsList">
      <group-games
        v-if="collection"
        :key="collection.id"
        showAllBtn
        showArrows
        :category="collection"
      />
    </template>
    
    <div
      v-if="hasBetsyIntegration"
      ref="sportsContainer"
      class="sports-container"
      @inview="startBetsyWidgets"
    >
      <div id="top-events-widget" />
      <div id="live-events-widget" />
    </div>

    <group-providers showArrows showAllBtn/>

    <activity-board
      v-if="activityBoardContent.showBlock && activityBoardContent.boards.length"
      v-bind="activityBoardContent"
    />

    <group-promotions />

    <atomic-seo-text v-if="currentLocaleContent?.pageMeta?.seoText" v-bind="currentLocaleContent.pageMeta.seoText"/>
  </div>
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia';
  import type { IHomePage } from '~/types';
  import type { ICollection } from '@skeleton/core/types';

  const globalStore = useGlobalStore();
  const { currentLocale } = storeToRefs(globalStore);

  const {
    localizePath,
    getContent,
    addBetsyScript
  } = useProjectMethods();

  const { currentLocaleContent, defaultLocaleContent } = await useContentLogic<IHomePage>({
    contentKey: 'homePageContent',
    contentRoute: ['pages', 'home'],
    isPage: true
  });

  const { getCollectionsList } = useGamesStore();
  const { data: gameCollections } = await useLazyAsyncData(() => getCollectionsList(), { server: false });
  
  const aeroCategory = computed(() => {
    return gameCollections.value?.find((collection) => collection.identity === currentLocaleContent.value?.aeroGroup?.collectionIdentity);
  });
  
  const targetGameCollections = computed(() => {
    return getContent(currentLocaleContent.value, defaultLocaleContent.value, 'gameCollections')?.map((item:ICollection) => item.identity) || []
  });
  
  const gameCollectionsList = computed(() => gameCollections.value?.filter((collection) => targetGameCollections.value.includes(collection.identity))?.sort((a, b) => {
    return targetGameCollections.value?.indexOf(a.identity) - targetGameCollections.value?.indexOf(b.identity);
  }));
  
  const cardsModifier = computed(() => {
    const length = Object.keys(getContent(currentLocaleContent.value, defaultLocaleContent.value, 'categories'))?.length || 0
    return length  ? `has-${length}-cards` : ''
  });

  const startBetsyWidgets = ():void => {
    const runtimeConfig = useRuntimeConfig();
    const mainHost = window.location.origin;
    const widgetsParams = {
      host: runtimeConfig.public.betsyParams?.clientHost,
      cid: runtimeConfig.public.betsyParams?.clientId,
      theme: runtimeConfig.public.betsyParams?.widgetTheme,
      customStyles: runtimeConfig.public.betsyParams?.widgetStyles ? `${mainHost}${runtimeConfig.public.betsyParams.widgetStyles}` : undefined,
      mainFrameUrl: mainHost + localizePath('/betting'),
      lang: currentLocale.value?.code || 'en',
      height: '372px',
    }

    if (window.BetSdk) {
      window.BetSdk.initTopEventsWidget({ ...widgetsParams, containerId: 'top-events-widget' });
      window.BetSdk.initLiveEventsWidget({ ...widgetsParams, containerId: 'live-events-widget' });
    } else {
      const betsyScript = addBetsyScript();
      betsyScript.onload = () => {
        window.BetSdk.initTopEventsWidget({ ...widgetsParams, containerId: 'top-events-widget' });
        window.BetSdk.initLiveEventsWidget({ ...widgetsParams, containerId: 'live-events-widget' });
      };
    }
  };

  const runtimeConfig = useRuntimeConfig();
  const hasBetsyIntegration = runtimeConfig.public.betsyParams?.clientHost && runtimeConfig.public.betsyParams?.clientId;
  const sportsContainer = ref();
  const { initObserver } = useProjectMethods();
  const widgetsObserver = ref();

  const initBetsy = (): void => {
    if (!hasBetsyIntegration) return;

    if (window.BetSdk) startBetsyWidgets();
    else {
      widgetsObserver.value = initObserver({
        once: true,
        settings: { root: null, rootMargin: '400px', threshold: 0 },
      });
      widgetsObserver.value.observe(sportsContainer.value);
    }
  }

  const activityBoardContent = computed(() => {
    const currentLocaleBoardContent = currentLocaleContent.value?.activityBoard;
    const defaultLocaleBoardContent = defaultLocaleContent.value?.activityBoard;
    return {
      showBlock: currentLocaleBoardContent?.showBlock,
      title: currentLocaleBoardContent?.title || defaultLocaleBoardContent?.title,
      icon: currentLocaleBoardContent?.icon || defaultLocaleBoardContent?.icon,
      columns: currentLocaleBoardContent?.columns || defaultLocaleBoardContent?.columns,
      boards: currentLocaleBoardContent?.boards?.length ? currentLocaleBoardContent.boards : defaultLocaleBoardContent?.boards || []
    }
  });

  onMounted(async () => {
    await getCollectionsList();
    initBetsy();
  })

  onBeforeUnmount(() => {
    if (sportsContainer.value && widgetsObserver.value) {
      widgetsObserver.value.unobserve(sportsContainer.value);
    }
  })
</script>

<style src="~/assets/styles/pages/index.scss" lang="scss" />

